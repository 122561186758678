import { useId, useState } from "react";
import { RichText } from "../richText/RichText.js";
import { minus, plus } from "../../icons/svg.js";

export interface IExpandableSection {
  title: string;
  text: string;
}

interface Props extends IExpandableSection {}

export const ExpandableSection: React.FC<Props> = ({ title, text }) => {
  const id = useId();
  const [active, setActive] = useState<boolean>(false);

  return (
    <div className="expandable-section">
      <div
        className="section-header"
        role="button"
        aria-expanded={active}
        aria-controls={id}
        onClick={() => setActive(!active)}
      >
        <h3 className="h6">{title}</h3>
        {active ? minus : plus}
      </div>

      {active && text && (
        <div id={id} className="section-body">
          <RichText html={text} />
        </div>
      )}
    </div>
  );
};
