import {
  ExpandableSection,
  IExpandableSection,
} from "../../internal-components/expandableSection/expandableSection.js";

interface Props {
  title?: string;
  sections?: IExpandableSection[];
}

const ExpandableSections: React.FC<Props> = ({ title, sections = [] }) => {
  const col = sections.reduce<React.ReactElement<IExpandableSection>[][]>(
    (acc, current, index) => {
      const targetArrayIndex = index % 2;
      acc[targetArrayIndex].push(
        <ExpandableSection
          key={index}
          title={current.title}
          text={current.text}
        />
      );
      return acc;
    },
    [[], []]
  );

  return (
    <div className="expandable-sections mw hs vs-xl">
      {title && <h2 className="h4">{title}</h2>}
      <div className="grid">
        <div className="column">{col[0]}</div>
        <div className="column">{col[1]}</div>
      </div>
    </div>
  );
};

export default ExpandableSections;
